<template>
  <div class="flex items-center">
    <vs-avatar :src="photoUrl" class="flex-shrink-0 mr-2" size="30px" @click="$router.push(url)" />
    <router-link :to="url" @click.stop.prevent class="text-inherit hover:text-primary">{{ params.value }}</router-link>
  </div>
</template>

<script>
export default {
  name: 'CellRendererLink',
  computed: {
    url() {
      return `/a/courses/${this.params.data.id}`;
    },
    photoUrl() {
      return `https://app-cdn.rapidseminars.com/thumbnails/${this.params.data.thumbnail_id||'default'}.jpeg`;
    },
  },
};
</script>
